export enum ProcedureCodeType {
  CPT = 'CPT',
  HCPCS = 'HCPCS',
}

export enum FilterType {
  INPUT = 'input',
  DATE_RANGE = 'date_range',
  MULTIPLE = 'multiple',
  SINGLE = 'single',
}

export enum FilterNames {
  DOS_RANGE = 'date_of_service_range',
  LINE_DOS_START = 'line_date_of_service_start',
  LINE_DOS_END = 'line_date_of_service_end',
  SORT_BY = 'sort_by',
  LOB = 'lob',
  START_DATE = 'start_date',
  END_DATE = 'end_date',
}

export enum AppConfigKeys {
  featureClaimLab = 'feature_claim_lab',
  featureClaimTimestamp = 'feature_claim_time_stamp',
  featureAccountApiClients = 'feature_account_api_clients',
  featureChatRip = 'feature_chat_rip',
  featureClaimIDRedirect = 'feature_claim_id_redirect',
  featureClientSSO = 'feature_client_sso',
  featureHiroTimestamps = 'feature_hiro_timestamps',
  featureClaimFiltering = 'feature_claim_filtering',
  featurePeriodicRefresh = 'feature_periodic_refresh',
  featurePolicyMetadata = 'feature_policy_metadata',
  featureZendeskLink = 'feature_zendesk_link',
  maintenance = 'maintenance',
  featurePolicySync = 'feature_policy_sync',
  featureClaimsExport = 'feature_claims_export',
}
